<template>
      <Page id="users" title="Fel!">
            <Alert show variant="danger" >{{errortext}}</Alert>
      </Page>
</template>

<script>
const Alert = () => import("@/components/miscellaneous/Alert");
const Page = () => import("@/components/common/Page");

export default {
      name: "Error",
      components: {
            Alert,
            Page,
      },
      data() {
            return {
                  path: ""
            };
      },
      computed:{
            errortext() {
                  return "Sidan '" + this.path + "' existerar inte. Vänligen kontakta sidans administratör";
            }
      },
      metaInfo() {
            return {
                  title: "FEL!",
                  meta: [{
                        name: "description",
                        content: "Ändra dina uppgifter!",
                  }, ],
            };
      },
      watch: {
            $route(to, from) {
                  if (to != from)
                        this.path = to.path;
            }
      },
      mounted() {
           this.path = this.$route.path;
      },
};
</script>
